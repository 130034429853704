<template>
  <div>

    <div 
      class="
        d-flex 
        justify-start 
        align-center" 
      style="
        height: 60px; 
        color: #d31145; 
        background-color: #fff; 
        padding: 0 20px 0 20px;">

      <v-icon 
        color="#d31145" 
        @click="$router.push('/inventory')">
        mdi-arrow-left

      </v-icon>

      <span 
        style="
          padding-left: 15px;
          font-weight: 700">
        Konversi Stok

      </span>
    </div>
    
    <v-divider></v-divider>

    <div>
      <div 
        class="
          d-flex 
          justify-space-around 
          align-center 
          pa-4" 
        style="
          background-color: #ffffff; 
          height:60px">
          
        <v-text-field
          dense
          v-model="searching"
          :hide-details="true"
          color="#ACACAC"
          filled
          rounded
          placeholder="Search..."
          elevation="0">
          <template v-slot:prepend-inner>
            <v-icon 
              color="#ACACAC">
              mdi-magnify
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon 
              color="#FF2353"  
              v-show="searching != ''"
              @click="
                searching = '';
                initialize()">
              mdi-close-circle
            </v-icon>
          </template>
        </v-text-field>

        <v-btn icon class="ml-4" color="#d31145">

          <v-icon>
            mdi-filter-variant
          </v-icon>

        </v-btn>

      </div>

      <v-card flat v-if="stock_conversion_list.length < 1 && searching == '' && !process.run">
        <v-card-text style="padding: 20px 20px">
          <div 
            style="
              font-weight: 700; 
              font-size: 16px;
              padding-bottom: 5px">
            Anda belum menambahkan Penyesuaian Stok
          </div>

          <div 
            style="
              font-weight: normal; 
              font-size: 14px; 
              color: #ACACAC;
              line-height: 1.5;
              padding-bottom: 5px">
            Anda bisa menambahkan dengan menekan tombol di bawah.
          </div>

          <div style="padding-top:20px">
            <v-btn 
              @click="dialog.form = false"
              rounded 
              depressed 
              color="#d31145" 
              elevation="0" 
              class="white--text text-capitalize">

              <v-icon left>
                mdi-plus
              </v-icon>
              
              Tambahkan
            
            </v-btn>
          
          </div>
        
        </v-card-text>
      
      </v-card>

      <v-card 
        flat 
        v-else-if="stock_conversion_list.length < 1 && searching != ''">

        <v-card-text
          class="d-flex align-center"
          style="height: calc(100vh - 200px); background: #f9f9f9; ">

            <v-img
              class="mx-auto"
              max-width="200"
              src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
            </v-img>

        </v-card-text>

      </v-card>

      <v-card flat v-else>
        <v-card-text 
          class="px-0 pb-0 py-0" 
          style="
            overflow: auto; 
            height: calc(100vh - 210px);
            background: #f9f9f9;
            padding-bottom: 65px !important;">

            <div v-if="stock_conversion_list.length < 1 && searching == '' && process.run">
              <v-skeleton-loader
                v-for="item in 15" :key="item"
                type="list-item-avatar-two-line">
              </v-skeleton-loader>
            </div>

          <v-list 
            dense 
            subheader 
            three-line 
            class="py-0">

            <template 
              v-for="(item, i) in stock_conversion_list">

              <v-list-item 
                :key="item.id" 
                class="
                  py-0 
                  cursor-pointer" 
                @click="getDetail(item)">

                <v-list-item-content>

                  <div 
                    class="
                      font-weight-bold 
                      mt-3" 
                    style="
                      color: #FA91AD; 
                      font-size: 18px">

                    {{ item.code }}

                  </div>

                  <v-list-item-title 
                    style="font-size: 16px;" 
                    class="
                      font-weight-bold 
                      text-title 
                      text-capitalize 
                      pt-0">

                    <div class="mt-5">

                      <div class="d-flex align-center mb-1">

                        <v-icon color="#FA91AD">
                          mdi-store
                        </v-icon>

                        <div 
                          class="
                            ml-3 
                            text-red">
                          {{ item.store_name }}
                        </div>
                      </div>

                      <div 
                        class="
                          d-flex 
                          align-center 
                          text-title">
                        
                        <v-icon color="#FA91AD">
                          mdi-account-tie
                        </v-icon>

                        <div class="ml-3">
                          {{ item.input_user_name }}
                        
                        </div>

                      </div>

                    </div>

                  </v-list-item-title>

                  <v-list-item-subtitle 
                    class="mt-4 px-0" 
                    style="
                      color: #989898;
                      line-height:1.5"> 

                      <div 
                        style="
                          color: #ACACAC; 
                          font-size: 14px">

                        Catatan Konversi : &nbsp;

                      </div>

                      <div 
                        style="font-size: 14px" 
                        class="text-title text-capitalize">

                        {{ item.note }} 

                      </div>
                    </v-list-item-subtitle>

                  <v-list-item-subtitle 
                    class="
                      text-end 
                      py-3 
                      font-italic" 
                      style="
                        color: #ACACAC; 
                        font-size: 14px">
                  
                    {{ item.input_datetime | dateFull }}

                  </v-list-item-subtitle>

                </v-list-item-content>

              </v-list-item>

              <v-divider :key="i" class="mx-auto"></v-divider>

            </template>
            
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <div 
      v-if="stock_conversion_list.length > 0" 
      class="d-flex justify-center align-center">

      <v-btn 
        class="
          float 
          white--text 
          text-capitalize" 
        @click="dialog.form = true" 
        style="right:100px" 
        rounded 
        color="#D31145">

        <v-icon 
          color="#fff" 
          class="my-float" 
          left>

          mdi-plus

        </v-icon>

        Tambahkan

      </v-btn>

      <v-btn 
        class="
          float 
          white--text 
          text-capitalize" 
          style="right:260px;" 
          rounded 
          color="#207245">

        <v-icon 
          color="#fff" 
          class="my-float" 
          left>
          mdi-microsoft-excel
        </v-icon>

        Export Excel

      </v-btn>
    </div>

    <v-dialog
      v-model="dialog.detail"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      fullscreen>

      <v-card flat>

        <v-card-title 
        style="
          height: 60px;
          color: #d31145;
          background-color: #fff;
          padding: 0 20px 0 20px;">

          <v-icon @click="dialog.detail = false" color="#d31145">
            mdi-arrow-left
          </v-icon>

          <span 
            style="
              padding-left: 15px;
              font-weight: 700;
              font-size: 20px">
            Detail Konversi Stok
          </span>

        </v-card-title>

        <v-divider></v-divider>

        <v-card-text 
          style="
            height: calc(100vh - 60px)" 
            class="px-0 py-0 pb-4">

          <v-list dense subheader three-line class="py-0">

            <div 
              class="text-red font-weight-bold px-4 pt-8" 
              style="font-size: 18px">

              Asal Konversi

            </div>

            <template v-for="(item, i) in detail.product_origin_array">

              <v-list-item :key="item.id" class="py-0">

                <v-list-item-content class="py-0">

                  <v-list-item-title 
                    style="font-size: 16px;" 
                    class="
                      d-flex 
                      align-center 
                      font-weight-bold 
                      text-title 
                      text-capitalize">

                    <div 
                      class="
                        text-capitalize 
                        text-title 
                        font-weight-bold" 
                      style="
                        font-size: 18px;
                        padding-right: 20px;flex: 1">

                      {{ item.name }}

                    </div>

                    <div 
                      style="
                        border: 1px solid #FA91AD57;
                        height:25px;">
                    </div>

                    <div 
                      class="
                        font-weight-bold 
                        text-red" 
                      style="
                        font-size: 18px;
                        padding-left: 20px;
                        flex: 1">

                      {{ item.code }}

                    </div>
                    
                  </v-list-item-title>

                </v-list-item-content>

              </v-list-item>

              <v-list-item :key="i"  class="py-0">

                <v-list-item-content class="py-0">

                  <v-simple-table class="py-0" dense>

                    <template>

                      <tbody>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">Kategori Produk</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-second pl-5 pr-0">
                            {{ item.category_name }}
                          </td>
                        </tr>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">Satuan Produk</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-second pl-5 pr-0 text-capitalize">
                            {{ item.unit }}
                          </td>
                        </tr>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">Stok Sebelum Konversi</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-second pl-5 pr-0">
                            {{ item.sys_qty }}
                          </td>
                        </tr>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">JumlahKonversi</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-second pl-5 pr-0">
                            {{ item.qty }}
                          </td>
                        </tr>

                      </tbody>

                    </template>

                  </v-simple-table>

                <v-list-item-subtitle 
                  class="text-end pt-0 font-italic" 
                  style="color:#ACACAC;font-size: 14px">

                  {{ item.input_datetime | dateFull }}

                </v-list-item-subtitle>

                </v-list-item-content>

              </v-list-item>

              <v-divider :key="i" class="mx-auto"></v-divider>

            </template>
            
          </v-list>

          <div style="border: 1px solid #E5E5E5;height:10px;background-color: #E5E5E5"></div>

          <v-list dense subheader three-line class="py-0">
            <div class="text-red font-weight-bold px-4 pt-8" style="font-size: 18px">
              Tujuan Konversi
            </div>
            <template v-for="(item, i) in detail.product_destination_array">
              <v-list-item :key="item.id" class="py-0">
                <v-list-item-content class="py-0">

                  <v-list-item-title 
                    style="font-size: 16px;" 
                    class="d-flex align-center font-weight-bold text-title text-capitalize">

                    <div 
                      class="text-capitalize text-title font-weight-bold" 
                      style="font-size: 18px;padding-right: 20px;flex: 1">

                      {{ item.name }}

                    </div>
                    <div style="border: 1px solid #FA91AD57;height:25px;"></div>
                    <div 
                      class="font-weight-bold text-red" 
                      style="font-size: 18px;padding-left: 20px;flex: 1">

                      {{ item.code }}

                    </div>

                  </v-list-item-title>
                  
                </v-list-item-content>

              </v-list-item>

              <v-list-item :key="i"  class="py-0">

                <v-list-item-content class="py-0">

                  <v-simple-table class="py-0" dense>

                    <template>

                      <tbody>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">Kategori Produk</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-second pl-5 pr-0">
                            {{ item.category_name }}
                          </td>
                        </tr>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">Satuan Produk</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-second pl-5 pr-0 text-capitalize">
                            {{ item.unit }}
                          </td>
                        </tr>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">Stok Sebelum Konversi</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-second pl-5 pr-0">
                            {{ item.sys_qty }}
                          </td>
                        </tr>

                        <tr>
                          <th class="text-start px-0" style="font-weight: 400" width="30%">JumlahKonversi</th>
                          <td class="text-center text-second px-0">:</td>
                          <td class="text-start text-second pl-5 pr-0">
                            {{ item.qty }}
                          </td>
                        </tr>

                      </tbody>

                    </template>

                  </v-simple-table>

                <v-list-item-subtitle 
                  class="text-end pt-0 font-italic" 
                  style="color:#ACACAC;font-size: 14px">

                  {{ item.input_datetime | dateFull }}

                </v-list-item-subtitle>

                </v-list-item-content>

              </v-list-item>

              <v-divider :key="i" class="mx-auto"></v-divider>

            </template>
            
          </v-list>

        </v-card-text>


      </v-card>

    </v-dialog>

  </div>
</template>

<script>
export default {

  data: () => ({
    searching: "",
    detail: {},
    stock_conversion_list: [
      {
        id: 1,
        store_name: "Alfian An - Naufal Nuha",
        code: "ST-KO-211213-00001",
        product_origin_array: [
          {
            id: 2,
            name: "Surya Pro Merah",
            code: "000001",
            unit: "pcs",
            category_name: "Uncategorized",
            category_id: "1",
            sys_qty: "0",
            qty: 1
          },
          {
            id: 3,
            name: "Antis Obat Kuman",
            code: "AN-TIS001",
            unit: "gram",
            category_name: "Uncategorized",
            category_id: "1",
            sys_qty: "0",
            qty: 2
          }
        ],
        product_destination_array: [
          {
            id: 4,
            name: "Sampoerna Mild",
            code: "000004",
            unit: "pcs",
            category_name: "Uncategorized",
            category_id: "1",
            sys_qty: "100",
            qty: 5
          }
        ],
        note: "Konversi Bahan terhadap Produk",
        input_datetime: "2021-12-13 11:45:39",
        input_user_id: "1",
        input_user_name: "Fahza Pergata"
      },
    ],
    process: {
      run: false
    },
    dialog: {
      form: false,
      selected: false,
      detail: false,
      confirmation: false,
    }
  }),
  components: {

  },
  watch: {

  },
  computed: {

  },
  created(){
    // this.initialize()
  },
  mounted(){

  },
  methods: {
    async initialize(){
      this.process.run = true;

      let params = {
        search: this.searching,
        sort: "",
        dir: "",
        limit: 15,
        page: 1
      }

      let res = await this.$get(`pos/stock-adjustment`, params)

      if (res.status == 200) {
        this.process.run = false
        this.stock_conversion_list = res.results.data
      }
    },
    getDetail(item){
      console.log(item);
      this.detail = item
      this.dialog.detail = true
    }
  }
}
</script>

<style>

</style>